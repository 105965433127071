//
// Table
//

.table-responsive {
  &:has(#planner-agency-list) {
    max-height: 800px;
    overflow-y: auto;
  }
}

.position-top-right {
  position: absolute;
  top: 5%;
  z-index: 1;
  right: 10%;
  color: white;
  border-radius: 5px;
  font-size: 9px;
  min-width: 35px;
  padding: 0.1rem;
}

.position-top-left {
  position: absolute;
  top: 10%;
  z-index: 1;
  left: 25%;
  color: white;
  border-radius: 5px;
  font-size: 9px;
  min-width: 35px;
  padding: 0.1rem;
}

.hover-effect {

  &:hover {
    @extend .default-radius;

    background: $gray-100;
  }
}

#campaignTable .table-responsive {
  max-height: 47vh;
  overflow: auto;
}

.table {

  tbody {
    font-size: $table-font-size;
    color: $dark-75;

    td {
      border: none;
      padding: 0.3rem;
    }
  }

  tfoot {
    th, td {
      font-size: $table-font-size;
      color: $dark-75;
      font-weight: $table-compact-head-font-weight;
    }
  }

  thead {
    th, td {
      font-weight: $table-head-font-weight;
      font-size: $table-head-font-size;
      border-bottom: none;
      color: $table-head-color;
    }
  }

  &.table-head-borderless {
    thead {
      th, td {
        border-top: 0;
      }
    }
  }

  &.table-head-solid {
    thead {
      th, td {
        background-color: $table-head-bg;
      }
    }
  }

  &.table-light {
    @each $name, $color in $theme-colors {
      &-#{$name} {
        thead {
          tr {
            color: $color;
            background-color: rgba($color, 0.1);
          }
        }
      }
    }
  }

  &.table-head-custom {
    thead {
      tr, th {
        font-weight: $table-compact-head-font-weight;
        color: $table-compact-head-color !important;
        font-size: $table-compact-head-font-size;
        text-transform: $table-compact-head-text-transform;
        letter-spacing: $table-compact-head-letter-spacing;
      }
    }
  }

  &.table-foot-custom {
    tfoot {
      th, td {
        font-weight: $table-compact-head-font-weight;
        color: $table-compact-head-color !important;
        font-size: $table-compact-head-font-size;
        text-transform: $table-compact-head-text-transform;
        letter-spacing: $table-compact-head-letter-spacing;
      }
    }
  }

  &.table-head-bg {
    thead {
      tr, th {
        background-color: $table-head-bg;
        border-bottom: 0;
        padding: 0.5rem;

        &:first-child {
          border-top-left-radius: $default-radius;
          border-bottom-left-radius: $default-radius;
        }

        &:last-child {
          border-top-right-radius: $default-radius;
          border-bottom-right-radius: $default-radius;
        }
      }
    }

    tbody {
      td {
        padding-top: 0.6rem;
      }
    }
  }

  &.table-foot-bg {
    tfoot {
      th, td {
        border-bottom: 0;
        background-color: $gray-100;

        &:first-child {
          border-top-left-radius: $border-radius;
          border-bottom-left-radius: $border-radius;
        }

        &:last-child {
          border-top-right-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
        }
      }
    }
  }

  &.table-separate {
    th, td {
      border-top: 0;
      border-bottom: 1px solid $table-border-color;

      &:first-child {
        padding-left: 0 !important;
      }

      &:last-child {
        padding-right: 0 !important;
      }
    }

    tfoot {
      th, td {
        border-bottom: 0;
        border-top: 1px solid $table-border-color;
      }
    }

    tbody {
      tr:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }

  &.table-bordered {
    tfoot {
      th, td {
        border-bottom: 0;
      }
    }
  }

  &.table-vertical-center {
    th, td {
      vertical-align: middle;
    }
  }

  &.dashboard-table {
    th:first-child {
      padding-left: 0;
    }
  }
}

.table:not(.table-bordered) {
  thead {
    th, td {
      border-top: 0;
    }
  }
}

#contentPlaner {
  th:first-child, td:first-child {
    position: sticky;
    left: 0px;
    background-color: $white;
  }

  th:first-child {
    background-color: $background-color;
  }

  td:first-child {

    display: flex;
    align-items: center;
    width: 220px;

    span {
      width: 100%;
      overflow-wrap: break-word !important;
      word-break: break-word;
    }
  }

  @media (max-width: 550px) {
    td:first-child {
      width: 170px;
    }
  }
}

.table-offer-items {
  .table th, .table td {
    vertical-align: middle;

    p {
      margin-bottom: 0px;
    }
  }

  table th {
    font-size: 14px;
  }
}


.table-head-color {
  color: $table-head-color
}

.sticky-table-header {
  position: sticky;
  top: 0;
  background-color: $white;
  border-bottom: 1px solid $table-border-color;
}
